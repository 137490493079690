<template>
	<div>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span v-if="types==1">数据列表</span>
					<span v-if="types==2">数据列表</span>
					<span v-if="types==3">专家列表</span>
					<span v-if="types==4">专家列表</span>
				</div>
				<div class="title-right">
					<el-input v-model="inputValue" :placeholder="types==1?'请输入货号':types==2?'请输入货号':types==3?'请输入专家编号':'请输入专家编号'"></el-input>
					<el-button type="primary" size="medium" style="margin:0 8px;" @click="insert()">{{types==1?'新增商品':types==2?'新增商品':types==3?'新增热门专家':'新增热门专家'}}</el-button>
				</div>
			</div>
			<div class="table" ref="tableContainer">
				<el-table
					ref="usertable"
					:data="tableData"
					border
					:header-cell-style="{ 'background-color': '#f9fafc' }"
					:cell-style="{ padding: 0 }"
					style="margin-bottom: 20px;"
					v-loading="isLoading"
				>
					<el-table-column prop="sort"  align="center" label="序号" show-overflow-tooltip width="230">
						<!-- <template slot-scope="scope">
							<div>{{ scope.$index + 1 }}</div>
						</template> -->
					</el-table-column>
					<el-table-column prop="refId" align="center" :label="types==1?'货号':types==2?'货号':types==3?'专家编号':'专家编号'" show-overflow-tooltip width="230"></el-table-column>
					<el-table-column align="center" label="商品图片">
						<template slot-scope="scope">
							<img class="goodImg" :src="photoURL + scope.row.img" alt="" />
						</template>
					</el-table-column>
					<el-table-column prop="name" align="center" label="商品名称" show-overflow-tooltip></el-table-column>
					<el-table-column v-if="types==1||types==2" prop="brandName" align="center" label="品牌名称" show-overflow-tooltip></el-table-column>
					<el-table-column align="center" label="操作" width="230">
						<template slot-scope="scope">
							<div style="min-height:40px">
								<el-button type="text" @click="remove(scope.row)">删除</el-button>
								<el-button type="text" @click="shelf(scope.row)">排序</el-button>
							</div>
						</template>
					</el-table-column>
					<template slot="empty">
						<el-empty :image-size="100" description="暂无数据"></el-empty>
					</template>
				</el-table>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			tableData: [],
			isLoading: false,
			inputValue: '',
			types: ''
		};
	},
	methods: {
		//展示数据
		list(type) {
			this.types = type;
			this.isLoading = true;
			this.$get('/shop-api/mgmt/hot/list?type=' + type)
				.then(res => {
					this.isLoading = false;
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(err => {
					//请求失败，关闭loading
					this.isLoading = false;
				});
		},
		//添加商品
		insert() {
			if(this.inputValue==''){
				this.$message.error('货号/专家号必填');
				return
			}
			this.$postJson('/shop-api/mgmt/hot/add', {
				refId: this.inputValue,
				type: this.types,
				sort: this.tableData.length + 1
			}).then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.$message.success(res.msg);
				//刷新表格
				this.list(this.types);
			});
		},

		//删除
		remove(row) {
			this.$confirm('是否确认删除？', '提示', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('/shop-api/mgmt/hot/delete?id=' + row.id).then(res => {
						if (res.code != 1000) {
							this.$message.error(res.msg);
							return;
						}
						this.$message.success(res.msg);
						//刷新表格
						this.list(this.types);
					});
				})
				.catch(() => {});
		},

		//上架
		shelf(row) {
			this.$prompt('请输入排序', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(({ value }) => {
				this.$postJson('/shop-api/mgmt/hot/update' , {
					id:row.id,
					sort:value
				}).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.$message.success(res.msg);
					//刷新表格
					this.list(this.types);
				});
			});
		},
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		}
	},
	mounted() {
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
.goodImg {
	min-width: 80px;
	height: 80px;
	margin-top: 5px;
}
.title-right {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}
</style>
