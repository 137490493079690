<template>
	<div>
		<div style="background-color: #ffffff;padding: 10px;height: 93vh;">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="热销设备" name="first"> <purchase ref="purchase"></purchase> </el-tab-pane>
				<el-tab-pane label="热租设备" name="second"><purchase ref="lease"></purchase></el-tab-pane>
				<el-tab-pane label="诊断专家" name="third"><purchase ref="diagnosis"></purchase></el-tab-pane>
				<el-tab-pane label="维修师傅" name="fourth"><purchase ref="maintenance"></purchase></el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>
<script>
import purchase from './purchase';		 //采购
export default {
	components: {
		purchase,
	},
	data() {
		return {
			activeName: 'first'
		};
	},
	methods: {
		handleClick(tab, event) {
			switch(tab.index*1){
				case 0:
					this.$refs.purchase.list(1)
				break;
				case 1:
					this.$refs.lease.list(2)
				break;
				case 2:
					this.$refs.diagnosis.list(3)
				break;
				case 3:
					this.$refs.maintenance.list(4)
				break;
			}
		}
	},
	mounted() {
		this.$refs.purchase.list(1)
	}
};
</script>
